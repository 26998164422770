<template>
  <div class="group-tab">
    <template v-if="isArea">
      <div v-for="area in list" :key="area.id">
        <div @click="onFolderClick(area.id)" class="group-tab-item d-flex align-center justify-space-between"
          :class="activeFolderId === area.id ? 'group-tab-item-active' : ''">
          <span>{{ area.name }}</span>
          <v-btn v-if="area.child?.length" class="ml-auto" text icon>
            <v-icon v-if="activeFolderId === area.id">mdi-arrow-up-drop-circle</v-icon>
            <v-icon v-else>mdi-arrow-down-drop-circle</v-icon>
          </v-btn>
          <v-menu v-if="showDots" offset-y left nudge-left="-15" nudge-top="-20" transition="none">
            <template v-slot:activator="{ on, attrs }">
              <v-btn text icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="handleAreaEdit(area)" class="list-item-button"> Edit restaurant group </v-list-item>
              <v-list-item v-if="area.isDeletable" @click="handleDeleteArea(area.id)" class="red--text list-item-button">
                Delete restaurant group
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <div v-if="area.id === activeFolderId">
          <div v-for="item in area.child" :key="item.id">
            <div @click="clickHandler(item.id)" class="group-tab-item d-flex align-center justify-space-between ml-5"
              :class="activeTabId === item.id ? 'group-tab-item-active' : ''">
              <span>{{ item.name || item.userName }}</span>
              <v-menu v-if="showDots" offset-y left nudge-left="-15" nudge-top="-20" transition="none">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn text icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="handleEdit(item)" class="list-item-button"> Edit restaurant subgroup </v-list-item>
                  <v-list-item @click="handleDelete(item.id)" class="red--text list-item-button">
                    Delete restaurant subgroup
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div v-for="item in list" :key="item.id">
        <div @click="clickHandler(item.id)" class="group-tab-item d-flex align-center justify-space-between"
          :class="activeTabId === item.id ? 'group-tab-item-active' : ''">
          <span>{{ item.name || item.userName }}</span>
          <v-menu v-if="showDots" offset-y left nudge-left="-15" nudge-top="-20" transition="none">
            <template v-slot:activator="{ on, attrs }">
              <v-btn text icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="handleEdit(item)" class="list-item-button"> Edit restaurant subgroup </v-list-item>
              <v-list-item @click="handleDelete(item.id)" class="red--text list-item-button">
                Delete restaurant group
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>
    </template>
    <Modal v-model="isModalOpen" :formData="activeListItem" :isLoading="isVenueGroupLoading" :areas="list"
      @saveForm="saveEdit" title="Edit restaurant subgroup" />
    <AreaModal v-model="isAreaModalOpen" :formData="activeAreaItem" :isLoading="isAreaLoading" @saveAreaForm="saveArea"
      title="Edit restaurant group" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Modal from '@/components/modals/Modal';
import AreaModal from '@/components/modals/AreaModal';
import { TOAST_TYPES } from '@/data';

export default {
  name: 'GroupTab',
  components: {
    Modal,
    AreaModal,
  },
  props: {
    isArea: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Array,
      default: () => [],
    },
    showDots: {
      type: Boolean,
      default: false,
    },
    activeFolderId: {
      type: [Number, String],
      default: 0
    },
    activeTabId: {
      type: [Number, String],
      default: 0
    },
  },
  data() {
    return {
      isModalOpen: false,
      isAreaModalOpen: false,
      activeListItem: '',
      activeAreaItem: '',
      activeAreaId: null,
    };
  },
  computed: {
    ...mapGetters({
      getUserData: 'app/getUserData',
      isAreaLoading: 'areas/isLoading',
      isVenueGroupLoading: 'venueGroups/isLoading'
    }),
  },
  methods: {
    ...mapActions({
      deleteVenueGroup: 'venueGroups/deleteItem',
      deleteArea: 'areas/deleteItem',
      editVenueGroup: 'venueGroups/changeItemToDB',
      editArea: 'areas/changeItemToDB',
      fetchAreas: 'areas/fetchData'
    }),
    // ...mapMutations({}),
    clickHandler(id) {
      if (this.activeTabId !== id) {
        this.$emit('tabClick', id);
      }
    },
    onFolderClick(id) {
      if (this.activeFolderId !== id) {
        this.$emit('folderClick', id)
      } else {
        this.$emit('folderClick', null)
      }
    },
    async handleDelete(id) {
      let res = await this.$dialog.confirm({
        text: 'Are you sure you want to delete?',
        title: 'Warning',
      });
      if (res) {
        this.deleteVenueGroup(id).then(() => {
          this.$toast.open({ message: 'Restaurant group has been successfully deleted!', type: TOAST_TYPES.SUCCESS });
        }).finally(() => {
          if (this.getUserData.roleNames[0].toLowerCase() === 'admin') {
            this.$emit('getAreasData')
          } else {
            this.$emit('getVenueGroups', this.getUserData.areaId)
          }
        });
      }
    },
    async handleDeleteArea(id) {
      let res = await this.$dialog.confirm({
        text: 'Are you sure you want to delete?',
        title: 'Warning',
      });
      if (res) {
        this.deleteArea(id).then(() => {
          this.$toast.open({ message: 'Restaurant group has been successfully deleted!', type: TOAST_TYPES.SUCCESS });
        }).finally(() => this.$emit('getAreasData'));
      }
    },
    handleEdit(item) {
      this.activeListItem = item;
      this.isModalOpen = true;
    },
    handleAreaEdit(item) {
      this.activeAreaItem = item;
      this.isAreaModalOpen = true;
    },
    saveEdit(form) {
      this.editVenueGroup(form).then(() => {
        this.isModalOpen = false;
        this.$toast.open({ message: 'Restaurant subgroup has been successfully edited!', type: TOAST_TYPES.SUCCESS });
      });
    },
    saveArea(form) {
      this.editArea(form).then(() => {
        this.isAreaModalOpen = false;
        this.$toast.open({ message: 'Restaurant group has been successfully edited!', type: TOAST_TYPES.SUCCESS });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.v-menu__content {
  border-radius: 8px;
}
</style>
