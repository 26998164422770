<template>
  <v-dialog v-model="syncedOpened" width="480" overlay-opacity="0.8">
    <div class="standart-modal-card">
      <v-form ref="modalForm" v-model="isFormValid" @submit.prevent="validateForm('modalForm')">
        <div class="standart-modal-card-top d-flex align-center justify-space-between">
          <h3>{{ title }}</h3>
          <v-btn @click="closeModal" icon color="black"><v-icon>mdi-close</v-icon> </v-btn>
        </div>
        <div class="standart-modal-card-middle">
          <v-select v-if="!isDealer" v-model="form.areaId" :items="areas" item-text="name" item-value="id"
            label="Select Group" :rules="[rules.required]" solo flat :disabled="isDealer">
          </v-select>
          <v-text-field class="standart-input-filled standart-input-no-message" v-model="form.name"
            label="Restaurant group name" :rules="[rules.required]" color="dark_grey" filled rounded dense
            :key="textFieldKey"></v-text-field>
        </div>
        <div class="standart-modal-card-bottom">
          <div class="d-flex align-center justify-end">
            <Button styleType="secondary" text="Cancel" class="standart-button-content-width mr-2" type="button"
              @handleClick="closeModal" />
            <Button text="Save" class="standart-button-content-width" :isLoading="isLoading" type="submit" />
          </div>
        </div>
      </v-form>
    </div>
  </v-dialog>
</template>

<script>
import Button from '@/components/commonComponents/Button';
import { mapGetters } from 'vuex';

export default {
  name: 'Modal',
  components: {
    Button,
  },
  model: {
    prop: 'opened',
    event: 'opened:update',
  },
  props: {
    opened: Boolean,
    formData: {
      type: [Object, String],
    },
    isLoading: Boolean,
    areas: {
      type: Array,
      default: () => { }
    },
    title: {
      type: String,
    },
  },
  data() {
    return {
      form: {
        areaId: '',
        id: '',
        name: '',
      },
      rules: {
        required: value => !!value || 'Required field',
      },
      textFieldKey: 100,
      isFormValid: false,
    };
  },
  watch: {
    formData(newVal) {
      if (newVal) {
        this.populateForm();
      }
    },
  },
  computed: {
    ...mapGetters({
      getUserData: 'app/getUserData'
    }),
    isDealer() {
      return this.getUserData.roleNames[0].toLowerCase() === 'dealer'
    },
    syncedOpened: {
      get() {
        return this.opened;
      },
      set(val) {
        return this.$emit('opened:update', val);
      },
    },
  },
  methods: {
    closeModal() {
      this.syncedOpened = false;
    },
    saveHandle() {
      let formData = {}
      if (this.isDealer) {
        formData = { ...this.form, areaId: this.getUserData.areaId }
      } else {
        formData = { ...this.form }
      }
      this.$emit('saveForm', formData);
    },
    populateForm() {
      this.form.areaId = this.formData.areaId;
      this.form.id = this.formData.id;
      this.form.name = this.formData.name;
    },
    clearForm() {
      this.textFieldKey += 1;
      this.form.name = '';
      this.form.id = '';
      this.form.areaId = '';
    },
    validateForm(ref) {
      this.isFormValid = this.$refs[ref].validate();
      if (this.isFormValid) {
        this.saveHandle();
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
