<template>
  <v-dialog v-model="syncedOpened" width="480" overlay-opacity="0.8">
    <div class="standart-modal-card">
      <v-form ref="areaModalForm" v-model="isFormValid" @submit.prevent="validateForm('areaModalForm')">
        <div class="standart-modal-card-top d-flex align-center justify-space-between">
          <h3>{{ title }}</h3>
          <v-btn @click="closeModal" icon color="black"><v-icon>mdi-close</v-icon> </v-btn>
        </div>
        <div class="standart-modal-card-middle">
          <v-text-field class="standart-input-filled standart-input-no-message mb-6" v-model="form.name"
            label="Restaurant group name" :rules="[rules.required]" color="dark_grey" filled rounded dense></v-text-field>
          <v-autocomplete v-model="form.timeZone" label="Time zone"
            class="standart-input-filled standart-input-no-message mb-6" color="dark_grey" :items="timeZones"
            item-text="name" item-value="id" loader-height="10" filled rounded dense>
          </v-autocomplete>
          <v-checkbox v-model="form.useDayLigthSavingTime" label="Use day light saving time" />
        </div>
        <div class="standart-modal-card-bottom">
          <div class="d-flex align-center justify-end">
            <Button styleType="secondary" text="Cancel" class="standart-button-content-width mr-2" type="button"
              @handleClick="closeModal" />
            <Button text="Save" class="standart-button-content-width" :isLoading="isLoading" type="submit" />
          </div>
        </div>
      </v-form>
    </div>
  </v-dialog>
</template>

<script>
import Button from '@/components/commonComponents/Button';
import { TIME_ZONES } from '../../data';

export default {
  name: 'AreaModal',
  components: {
    Button,
  },
  model: {
    prop: 'opened',
    event: 'opened:update',
  },
  props: {
    opened: Boolean,
    formData: {
      type: [Object, String],
    },
    isLoading: Boolean,
    title: {
      type: String,
    },
  },
  data() {
    return {
      form: {
        id: '',
        name: '',
        timeZone: 0,
        useDayLigthSavingTime: false
      },
      timeZones: [...TIME_ZONES],
      rules: {
        required: value => !!value || 'Required field',
      },
      isFormValid: false,
    };
  },
  watch: {
    formData(newVal) {
      if (newVal) {
        this.populateForm();
      }
    },
  },
  computed: {
    syncedOpened: {
      get() {
        return this.opened;
      },
      set(val) {
        return this.$emit('opened:update', val);
      },
    },
  },
  methods: {
    closeModal() {
      this.syncedOpened = false;
    },
    async saveHandle() {
      let formData = { ...this.form };
      this.$emit('saveAreaForm', formData);
    },
    populateForm() {
      this.form.id = this.formData.id;
      this.form.name = this.formData.name;
      this.form.timeZone = this.formData.timeZone;
      this.form.useDayLigthSavingTime = this.formData.useDayLigthSavingTime;
    },
    clearForm() {
      this.form.name = '';
      this.form.id = '';
      this.form.timeZone = ''
      this.form.useDayLigthSavingTime = false
    },
    validateForm(ref) {
      this.isFormValid = this.$refs[ref].validate();
      if (this.isFormValid) {
        this.saveHandle();
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
